import * as React from 'react';
import PropTypes from 'prop-types';
import Delay from '../Delay';
import Socials from '../Socials';
import { footer } from './footer.module.scss';

const Footer = ({ delay = 0 }) => {
  return (
    <footer className={footer}>
      <Delay delay={delay}>
        <Socials />
        <p>© Ian Goodnight 2021</p>
      </Delay>
    </footer>
  );
};

Footer.propTypes = { delay: PropTypes.number };

export default Footer;
