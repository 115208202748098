import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { socials } from './socials.module.scss';

const Socials = () => {
  return (
    <div className={socials}>
      <a href="https://github.com/iangoodnight" rel="noopener no referrer">
        <StaticImage
          alt="github"
          height={40}
          layout="fixed"
          objectFit="contain"
          placeholder="blurred"
          src="../../images/github.png"
        />
      </a>
      <a
        href="https://stackoverflow.com/users/8513563/ian-goodnight"
        rel="noopener noreferrer"
      >
        <StaticImage
          alt="stackoverflow"
          height={40}
          layout="fixed"
          objectFit="contain"
          placeholder="blurred"
          src="../../images/stackoverflow.png"
        />
      </a>
      <a
        href="https://www.linkedin.com/in/iangoodnight/"
        rel="noreferrer noopener"
      >
        <StaticImage
          alt="linkedin"
          height={40}
          layout="fixed"
          objectFit="contain"
          placeholder="blurred"
          src="../../images/linkedin.png"
        />
      </a>
    </div>
  );
};

export default Socials;
