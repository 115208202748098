import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Delay from '../Delay';
import Fadeout from '../Fadeout';
import Ghosty from '../Ghosty';
import Typewriter from '../Typewriter';
import { hero, typewriter } from './hero.module.scss';

const Hero = ({ initialDelay = 0, typed }) => {
  return (
    <section>
      {typed && (
        <Fadeout className={typewriter} delay={initialDelay}>
          <p>
            $>&nbsp;
            <Typewriter delay={150}>{typed}</Typewriter>
          </p>
        </Fadeout>
      )}
      <Delay delay={initialDelay ? initialDelay + 700 : 0}>
        <div className={hero}>
          <Link to="/#loaded">
            <h1>Ian Goodnight</h1>
          </Link>
          <Ghosty />
        </div>
      </Delay>
    </section>
  );
};

Hero.propTypes = {
  initialDelay: PropTypes.number,
  typed: PropTypes.string,
};

export default Hero;
