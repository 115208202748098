import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { typewriter } from './typewriter.module.scss';

const Typewriter = ({ children, delay = 0, wait = 1500 }) => {
  const [text, setText] = useState('');

  const [char, setChar] = useState(0);

  const [waitTime, setWaitTime] = useState(wait);

  useEffect(() => {
    if (char > children.length) return;
    setTimeout(() => {
      setText(children.slice(0, char));
    }, delay + char * delay + waitTime);
    setChar(char + 1);
    setWaitTime(0);
  }, [char, children, delay, waitTime]);

  return <span className={typewriter}>{text}</span>;
};

Typewriter.propTypes = {
  children: PropTypes.string,
  delay: PropTypes.number,
  wait: PropTypes.number,
};

export default Typewriter;
