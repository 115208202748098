import * as React from 'react';
import PropTypes from 'prop-types';
import './layout.module.scss';
import Footer from '../Footer';

const Layout = ({ children, footerDelay = 5000 }) => {
  return (
    <>
      <main>{children}</main>
      <Footer delay={footerDelay} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
  footerDelay: PropTypes.number,
};

export default Layout;
