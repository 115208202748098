import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fade, fadeout } from './fadeout.module.scss';

const Fadeout = ({ children, className = '', delay = 6000 }) => {
  const [faded, setFaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setFaded(true);
    }, delay);
  }, [delay]);

  return (
    <div className={`${fadeout} ${className} ${faded ? fade : ''}`}>
      {children}
    </div>
  );
};

Fadeout.propTypes = {
  children: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
  className: PropTypes.string,
  delay: PropTypes.number,
};

export default Fadeout;
