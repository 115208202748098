import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { hero, show } from './delay.module.scss';

const Delay = ({ children, delay = 5000 }) => {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setHidden(false);
    }, delay);
  }, [delay]);

  return <div className={hidden ? hero : `${hero} ${show}`}>{children}</div>;
};

Delay.propTypes = {
  children: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
  delay: PropTypes.number,
};

export default Delay;
