import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import favicon from '../../images/retro-game.png';

const Metadata = ({ title, description }) => {
  const data = useStaticQuery(
    graphql`
      query metaQuery {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaTitle = title || data.site.siteMetadata.title;

  const metaDescription = description || data.site.siteMetadata.description;

  return (
    <Helmet>
      <html lang="en" />
      <title>{`${metaTitle} | ${data.site.siteMetadata.title}`}</title>
      <meta name="description" content={metaDescription} />
      <meta name="author" content={data.site.siteMetadata.author} />
      <link rel="icon" href={favicon} />
    </Helmet>
  );
};

Metadata.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Metadata;
