import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { ghost, lurk } from './ghosty.module.scss';

const Ghosty = () => {
  return (
    <div className={ghost}>
      <div className={lurk}>
        <StaticImage
          alt="retro ghost icon"
          height={30}
          layout="fixed"
          objectFit="contain"
          placeholder="blurred"
          src="../../images/retro-game.png"
        />
      </div>
    </div>
  );
};

export default Ghosty;
